import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import APIS from "./utils/APIS"
import { isLoggedIn, setUser } from "./utils/auth"

import { ConfirmProvider } from "material-ui-confirm";

const checkLoggedRefresh = () => {
  if (isLoggedIn()) {
    APIS.getLoginStatus().then(result => {
      if (result?.message == "status") {
        //TODO -> check des infos par rapport au gatsbyUser en storage
      } else {
        setUser({})
        document.location.reload()
      }
    })
  }
}
checkLoggedRefresh()


ReactDOM.render(
  (
    <BrowserRouter>
      <ConfirmProvider>
        <App />
      </ConfirmProvider>
    </BrowserRouter>
  ),
  document.getElementById('root')
);
